import { api } from '@/services/client'
import {
  PaginationList,
  PaginationSelectModel,
  serviceUrl,
} from '@/services/utils'
import { AxiosInstance } from 'axios'

const controller = serviceUrl('technology')

function createTechnologyService(client: AxiosInstance) {
  return {
    quote(params: TechnologyQuoteSelectModel) {
      return client.get<ArrayBuffer>(controller.action('quote'), {
        responseType: 'arraybuffer',
        params,
      })
    },
    get(params: TechnologySelectModel) {
      return client.get<PaginationList<TechnologyViewModel>>(
        controller.action('get'),
        {
          params,
        }
      )
    },
  }
}

export const technologyService = createTechnologyService(api.base)

export interface TechnologyQuoteSelectModel {
  quoteId?: string | null
  proposedTechnology: string | null
  wasteWaterFlowToBeTreated: string | null
  biochemicalOxygenDemandConcentration: string | null
  complianceWithTheStandard: string | null
  requiredArea: string | null
  investmentBaseBudget: string | null
  waterFlowToBeTreatedPerDay: string | null
  complianceWithStandard: string | null
  fieldOfBusiness: string | null
  residentialType: string | null
  rangeOfPeople: string | null
  commerceType: string | null
  waterType: string | null
  sectorType: string | null
  typeOfBird: string | null
  animalQuantity: string | null
  tonsOfChickensPerDay: string | null
  siteType: string | null
  animalType: string | null
  numberOfShifts: string | null
  shiftEmployerAmount: string | null
  waterConsumptionPerDay: string | null
  email: string | null
}

export interface TechnologySelectModel extends PaginationSelectModel {
  name: string
}
export interface TechnologyViewModel {
  technologyId: string
  technologyKey: number
  name: string
  advantages: string
  imagePath: string
  illustrationPath: string
}
