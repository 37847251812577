import { PaginationList, PaginationSelectModel } from '@/services/utils'
import { Ref, ref, watch, WatchSource } from '@vue/composition-api'
import { AxiosResponse } from 'axios'

export function useServicePaginated<T extends PaginationSelectModel, K>(
  getKey: WatchSource<T & { languageId?: string }>,
  fetcher: (...args: any) => Promise<AxiosResponse<PaginationList<K>>>
) {
  const data = ref([]) as Ref<K[]>
  const totalItems = ref(0)
  const isLoading = ref(false)
  const error = ref(null)

  watch(
    getKey,
    async () => {
      try {
        isLoading.value = true
        const response = await fetcher()
        data.value = response.data.items
        totalItems.value = response.data.totalItems
      } catch (e) {
        error.value = e
      } finally {
        isLoading.value = false
      }
    },
    {
      deep: true,
      immediate: true,
    }
  )

  return {
    data,
    totalItems,
    isLoading,
    error,
  }
}
