import {
  TechnologyQuoteSelectModel,
  TechnologySelectModel,
  technologyService,
} from '@/services/modules/technology'
import { computed } from '@vue/composition-api'
import { useServicePaginated } from './useServicePaginated'

export function useTechnology(model: TechnologySelectModel) {
  const request = computed<TechnologySelectModel>(() => ({
    ...model,
  }))
  return useServicePaginated(request, () =>
    technologyService.get(request.value)
  )
}

export async function useTechnologyQuote(model: TechnologyQuoteSelectModel) {
  const request = computed<TechnologyQuoteSelectModel>(() => ({
    ...model,
  }))

  const { data } = await technologyService.quote(request.value)

  if (!request.value.email) {
    const blob = new Blob([data], { type: 'application/pdf' })
    const blobUrl = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    const d = new Date()
    link.href = blobUrl
    link.download = `ficha-técnica-sed-${
      model.proposedTechnology
    }-${d.getDate()}-${d.getMonth()}-${d.getFullYear()}`
    link.click()
    URL.revokeObjectURL(blobUrl)
    link.remove()
  }
}
